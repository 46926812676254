






















































import {Component, Vue} from 'vue-property-decorator';
import EditModelBankAccount from '@/components/modals/EditModelBankAccount.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import {PerformerFileResponse} from '@/types/performerFileResponse';
import moment from 'moment';
import ContactUsModalNew from '@/components/modals/ContactUsModalNew.vue';

@Component({
  components: {EditModelBankAccount, ContactUsModalNew},
})
export default class ModelProfileBankAccount extends Vue {
  idImg1: PerformerFileResponse | null = null;
  idImg2: PerformerFileResponse | null = null;
  showContactUs = false;

  get user(): ModelDataInterface {
    return vxm.user.data;
  }

  mounted() {
    if (this.user.idImg1) {
      vxm.model
        .getPerformerFile(this.user.idImg1)
        .then((res) => {
          this.idImg1 = res.data;
        })
        .catch((error) => {
          return error;
        });
    }

    if (this.user.idImg2) {
      vxm.model
        .getPerformerFile(this.user.idImg2)
        .then((res) => {
          this.idImg2 = res.data;
        })
        .catch((error) => {
          return error;
        });
    }
  }

  formatDate(date: string) {
    return moment(date).format('MM-DD-YYYY');
  }

  logout() {
    this.$store.dispatch('resetStore').then(() => {
      localStorage.removeItem('model');
      localStorage.removeItem('fan');
      this.$socket.disconnect();
      this.$router.push('/login');
    });
  }
}
